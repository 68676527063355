const subheadingsData = {
  1: [
    {
      title: "About Me",
      content: (
        <>
          <p>
            Fullstack Developer with a Diploma in Full Stack Software
            Development from Code Institute. Transitioned from a gaming coach to
            a tech enthusiast, equipped with strong problem-solving skills and a
            keen eye for detail. I specialize in building responsive,
            user-focused web applications using modern frontend frameworks like
            React and TypeScript. My experience in both individual projects and
            coaching has honed my communication abilities and passion for
            creating intuitive digital experiences.
          </p>
        </>
      ),
    },
  ],
  2: [
    {
      title: "2024 Full Stack Software Development Diploma",
      content: (
        <>
          <p>
            Code Institute – Successfully completed a comprehensive full-stack
            development program covering HTML, CSS, JavaScript, Python, Django,
            and advanced frontend development with React. This curriculum
            provided me with the skills to build dynamic, responsive web
            applications from scratch, integrating both frontend and backend
            technologies to create complete, full-stack solutions.
          </p>
        </>
      ),
    },
    {
      title: "2023 Self-Taught Developer",
      content: (
        <>
          <p>
            Developed foundational skills in full-stack development,
            independently exploring technologies such as HTML, CSS, JavaScript,
            and Python. This self-directed journey fostered a mindset of
            continuous learning and adaptability, laying a solid foundation for
            the structured training I later received at Code Institute.
          </p>
        </>
      ),
    },
  ],
  3: [
    {
      title: "2023 Video Content Creator & Coach at AimLab.gg",
      content: (
        <>
          <p>
            Created and delivered personalized coaching content through video on
            AimLab.gg, a platform blending interactive forums with customized
            video responses. Engaged directly with clients to answer questions
            and provide detailed feedback, fostering a supportive learning
            community and enhancing client engagement through effective visual
            content.
          </p>
        </>
      ),
    },
    {
      title: "2022 Scriptwriter for ProGuides YouTube Channel",
      content: (
        <>
          <p>
            Developed narrative-driven scripts for ProGuides' YouTube channel,
            engaging both new and veteran players with content that combined
            expert tips and storytelling. This role allowed me to create
            informative and entertaining material, significantly contributing to
            a high-quality viewing experience for an audience exceeding one
            million subscribers.
          </p>
        </>
      ),
    },
    {
      title: "2019 Valorant & Fortnite Coach for ProGuides",
      content: (
        <>
          <p>
            Delivered one-on-one coaching for over 2000 clients on ProGuides,
            specializing in player development for Valorant and Fortnite.
            Customized coaching techniques to suit diverse learning styles,
            maintained an up-to-date understanding of game metas, and nurtured a
            positive, motivating environment that promoted skill improvement and
            client satisfaction.
          </p>
        </>
      ),
    },
  ],
};

export default subheadingsData;
